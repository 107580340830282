import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/builds/proscom/hse-design/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Playground } from 'docz';
import { Space, Table, TableBody, TableCell, TableHead, TableHeadCell, TableRow } from '@hse-design/react';
import { P, Link, ComponentLinks } from '../common';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "grid"
    }}>{`Grid`}</h1>
    <ComponentLinks figma={'https://www.figma.com/file/cBHD57QcCt9WDT4e7e2B3w/hse_general_library?node-id=1016%3A47'} storybook={'/?path=/story/grid-grid--playground'} vueStorybook={'/?path=/story/grid-grid--playground'} mdxType="ComponentLinks" />
    <h2 {...{
      "id": "описание"
    }}>{`Описание`}</h2>
    <p>{`Grid – это сетка страницы. Мы используем гибкую сетку: ширина столбцов, расстояние между ними, отступы с краев меняются при изменении `}<a parentName="p" {...{
        "href": "#%D0%B1%D1%80%D0%B5%D0%B9%D0%BA%D0%BF%D0%BE%D0%B8%D0%BD%D1%82%D1%8B"
      }}>{`брейкпоинта`}</a>{`.`}</p>
    <p>{`Отдельных компонентов для сетки и ее колонок нет. Используйте класс `}<inlineCode parentName="p">{`grid`}</inlineCode>{` для контейнера колонок и классы вида `}<inlineCode parentName="p">{`col- <брейкпоинт> - <количество колонк>`}</inlineCode>{` для колонок. Использование смотрите в `}<a parentName="p" {...{
        "href": "#%D0%BF%D1%80%D0%B8%D0%BC%D0%B5%D1%80%D1%8B"
      }}>{`примерах ниже`}</a>{`.`}</p>
    <p>{`Вы можете не дублировать количество колонок для каждого разрешения, если их количество повторяется. То есть количество колонок, выставленных классом `}<inlineCode parentName="p">{`col-mobile-small-X`}</inlineCode>{` сработает на `}<inlineCode parentName="p">{`mobile-small`}</inlineCode>{` `}<a parentName="p" {...{
        "href": "#%D0%B1%D1%80%D0%B5%D0%B9%D0%BA%D0%BF%D0%BE%D0%B8%D0%BD%D1%82%D1%8B"
      }}>{`брейкпоинте`}</a>{` и дальше.`}</p>
    <p>{`У сетки два варианта отображения: с сайдбаром и без него. При использовании сайдбара добавьте классу `}<inlineCode parentName="p">{`grid`}</inlineCode>{` модификатор `}<inlineCode parentName="p">{`sidebar`}</inlineCode>{` со значениями `}<inlineCode parentName="p">{`fixed`}</inlineCode>{` для фиксированного сайдбара, или `}<inlineCode parentName="p">{`dynamic`}</inlineCode>{` для динамического. Подробнее `}<a parentName="p" {...{
        "href": "#%D1%81%D0%B0%D0%B9%D0%B4%D0%B1%D0%B0%D1%80"
      }}>{`ниже в примерах`}</a></p>
    <h2 {...{
      "id": "брейкпоинты"
    }}>{`Брейкпоинты`}</h2>
    <p>{`Брейкпоинт – это минимальное разрешение экрана, при достижении которого меняются настройки сетки.`}</p>
    <p>{`Можно не указывать количество колонок для каждого разрешения, тогда количество колонок будет равно настройкам ближайшего меньшего брейкопинта.`}</p>
    <p>{`Всего есть 7 брейкпоинтов:`}</p>
    <Table style={{
      width: '100%',
      maxWidth: '768px'
    }} mdxType="Table">
  <TableHead mdxType="TableHead">
    <TableRow mdxType="TableRow">
      {['Название', 'Минимальное разрешение экрана', 'Количество колонок'].map((item, index) => <TableHeadCell key={index} mdxType="TableHeadCell">{item}</TableHeadCell>)}
    </TableRow>
  </TableHead>
  <TableBody mdxType="TableBody">
  {[["mobile-small", "320px", "4"], ["mobile-large", "375px", "4"], ["tablet-small", "768px", "8"], ["tablet-large", "1024px", "12"], ["desktop-small", "1280px", "12"], ["desktop-medium", "1440px", "12"], ["desktop-large", "1920px", "16"]].map((row, rowIndex) => <TableRow key={rowIndex} mdxType="TableRow">
      {row.map((item, itemIndex) => <TableCell key={itemIndex} mdxType="TableCell">{item}</TableCell>)}
    </TableRow>)}
  </TableBody>
    </Table>
    <h2 {...{
      "id": "примеры"
    }}>{`Примеры`}</h2>
    <h3 {...{
      "id": "колонки"
    }}>{`Колонки`}</h3>
    <p>{`Для каждого брейкпоинта можно установить нужное количество колонок. Для этого используйте класс вида `}<inlineCode parentName="p">{`col- <брейкпоинт> - <количество колонк>`}</inlineCode>{`.`}</p>
    <p>{`Ширина столбцов, расстояние между ними, отступы с краев меняются при изменении брейкпоинта.`}</p>
    <p>{`Все колонки должны лежать внутри элемента с классом `}<inlineCode parentName="p">{`grid`}</inlineCode></p>
    <Playground __position={2} __code={'<div className=\"grid\" style={{ justifyContent: \'flex-start\', zoom: 0.6 }}>\n  <div\n    className={`\n      col-mobile-small-2\n      col-mobile-large-2\n      col-tablet-small-5\n      col-tablet-large-7\n      col-desktop-medium-6\n      col-desktop-large-7\n    `}\n    style={{\n      height: \'350px\',\n      padding: \'10px 7px\',\n      backgroundColor: \'rgb(211, 215, 225)\',\n      textAlign: \'center\',\n    }}\n  />\n  <div\n    className={`\n      col-mobile-small-2\n      col-mobile-large-2\n      col-tablet-small-3\n      col-tablet-large-5\n      col-desktop-medium-6\n      col-desktop-large-9\n    `}\n    style={{\n      height: \'350px\',\n      padding: \'10px 7px\',\n      backgroundColor: \'rgb(211, 215, 225)\',\n      textAlign: \'center\',\n    }}\n  />\n</div>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Space,
      Table,
      TableBody,
      TableCell,
      TableHead,
      TableHeadCell,
      TableRow,
      P,
      Link,
      ComponentLinks,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <div className="grid" style={{
        justifyContent: 'flex-start',
        zoom: .6
      }}>
    <div className={`
        col-mobile-small-2
        col-mobile-large-2
        col-tablet-small-5
        col-tablet-large-7
        col-desktop-medium-6
        col-desktop-large-7
      `} style={{
          height: '350px',
          padding: '10px 7px',
          backgroundColor: 'rgb(211, 215, 225)',
          textAlign: 'center'
        }} />
    <div className={`
        col-mobile-small-2
        col-mobile-large-2
        col-tablet-small-3
        col-tablet-large-5
        col-desktop-medium-6
        col-desktop-large-9
      `} style={{
          height: '350px',
          padding: '10px 7px',
          backgroundColor: 'rgb(211, 215, 225)',
          textAlign: 'center'
        }} />
  </div>
    </Playground>
    <h3 {...{
      "id": "сайдбар"
    }}>{`Сайдбар`}</h3>
    <p>{`При использовании сайдбара классу сетки `}<inlineCode parentName="p">{`grid`}</inlineCode>{` нужно добавить модификатор `}<inlineCode parentName="p">{`sidebar`}</inlineCode>{` со значением `}<inlineCode parentName="p">{`fixed`}</inlineCode>{` или `}<inlineCode parentName="p">{`dynamic`}</inlineCode>{` (например, `}<inlineCode parentName="p">{`grid grid_sidebar_fixed`}</inlineCode>{` или `}<inlineCode parentName="p">{`grid grid_sidebar_dynamic`}</inlineCode>{`).`}</p>
    <p>{`Компонент сайдбара нужно положить в колонку с классом `}<inlineCode parentName="p">{`col-sidebar`}</inlineCode>{`.`}</p>
    <p>{`На разрешениях `}<inlineCode parentName="p">{`tablet-small`}</inlineCode>{`, `}<inlineCode parentName="p">{`mobile-large`}</inlineCode>{`, `}<inlineCode parentName="p">{`mobile-small`}</inlineCode>{` колонка с сайдбаром скрывается`}</p>
    <Playground __position={3} __code={'() => {\n  const columnStyles = {\n    height: \'100vh\',\n    padding: \'15px\',\n    backgroundColor: \'rgb(211, 215, 225)\',\n    textAlign: \'center\',\n  }\n  return (\n    <>\n      <div\n        className=\"grid grid_sidebar_fixed\"\n        style={{ \'justify-content\': \'flex-start\', zoom: 0.6 }}\n      >\n        <div className=\"col-sidebar\" style={columnStyles}>\n          Sidebar fixed\n        </div>\n        {new Array(12).fill(null).map((_, i) => (\n          <div className=\"col-mobile-small-1\" style={columnStyles}>\n            {i + 1}\n          </div>\n        ))}\n      </div>\n      <Space size=\"small_x\" vertical />\n      <div\n        className=\"grid grid_sidebar_dynamic\"\n        style={{ \'justify-content\': \'flex-start\', zoom: 0.6 }}\n      >\n        <div className=\"col-sidebar\" style={columnStyles}>\n          Sidebar dynamic\n        </div>\n        {new Array(12).fill(null).map((_, i) => (\n          <div className=\"col-mobile-small-1\" style={columnStyles}>\n            {i + 1}\n          </div>\n        ))}\n      </div>\n    </>\n  )\n}'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Space,
      Table,
      TableBody,
      TableCell,
      TableHead,
      TableHeadCell,
      TableRow,
      P,
      Link,
      ComponentLinks,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
      {() => {
        const columnStyles = {
          height: '100vh',
          padding: '15px',
          backgroundColor: 'rgb(211, 215, 225)',
          textAlign: 'center'
        };
        return <>
      <div className="grid grid_sidebar_fixed" style={{
            'justify-content': 'flex-start',
            zoom: .6
          }}>
        <div className="col-sidebar" style={columnStyles}>
          Sidebar fixed
        </div>
        {new Array(12).fill(null).map((_, i) => <div className="col-mobile-small-1" style={columnStyles}>
            {i + 1}
          </div>)}
      </div>
      <Space size="small_x" vertical mdxType="Space" />
      <div className="grid grid_sidebar_dynamic" style={{
            'justify-content': 'flex-start',
            zoom: .6
          }}>
        <div className="col-sidebar" style={columnStyles}>
          Sidebar dynamic
        </div>
        {new Array(12).fill(null).map((_, i) => <div className="col-mobile-small-1" style={columnStyles}>
            {i + 1}
          </div>)}
    </div>
  </>;
      }}
    </Playground>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      